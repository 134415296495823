import NotFound from '../views/NotFound.vue'
// import Preview from '../views/Preview.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: 'Home'
    },
    component: () =>
      import("../views/HomeLight.vue"),
  },
  {
    path: '*',
    name: NotFound,
    meta: {
      title: 'Preview'
    },
    component: () => import("../views/NotFound.vue")

  }
];
const router = new VueRouter({
  mode: 'history',
  // base: '/The_ig_shop/',
  routes: routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | This is The Shop';
  }
});


export default router;