module.exports = {

    Home: {
        intro: '為企業，學校，政府部門提供禮品、贈品及紀念品設計及批發訂製服務。可按客戶要求在禮品上印上LOGO或圖案。​我們以低起訂量，價錢實惠及特快服務為目標。',

    },
    HomeLight: {
        brandName: 'This is The Shop',
        home: '主頁',
        itemDetail: '禮品/贈品/紀念品 參考例子',
        contactUs: '聯絡我們'
    }


}



