module.exports = {
    Home: {
        intro: 'At This is The Shop we provide design and wholesale custom services for gifts, accessories, and souvenirs to businesses, schools, and government departments. We can imprint logos or designs on the gifts according to customer requirements. Our goal is to offer high-quality custom services for corporate gifts and promotional giveaways, with a focus on low minimum order quantities, affordable prices, and fast delivery.',

    },
    HomeLight: {
        brandName: 'This is The Shop',
        home: 'Home',
        itemDetail: 'Example of gift/accessories/souvenirs',
        contactUs: 'Contact Us'
    }


}


