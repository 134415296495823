import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/style.scss'

import App from './App.vue'
import CoolLightBox from 'vue-cool-lightbox';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localization from './localization'
import router from './router'

Vue.use(VueI18n)
Vue.use(CoolLightBox);
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'chi',
  messages: localization
});

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$changeLocale = function (locale) {
  this.$i18n.locale = locale
};
